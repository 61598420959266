import { Add } from "@mui/icons-material";
import React from "react";
import useStyles from "./styles";
import { AddProperties } from "./types";

const AddComponent = (properties: AddProperties) => {
    const { onClick } = properties;
    const { addBackground, addSymbol, addButtonContainer } = useStyles();

    return (
        <div className={addButtonContainer}>
            <div className={addBackground}>
                <Add className={addSymbol} onClick={() => onClick()} />
            </div>
        </div>
    );
};

export default AddComponent;
