import React from "react";
import { Item } from "./Item";

import useStyles from "./styles";
import { MonthItemProperties } from "./types";

const MonthItem = ({ entries, monthName }: MonthItemProperties) => {
    const { monthLabel } = useStyles();
    return (
        <>
            <p className={monthLabel}>{monthName}</p>
            {entries.map((element, index) => (
                <Item entry={element} key={index} />
            ))}
        </>
    );
};

export default MonthItem;
