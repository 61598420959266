import { createUseStyles } from "react-jss";
import { AppTheme } from "../../theme/types";

const styles = createUseStyles(({unit, colors: {grayLight, white, gray}}: AppTheme) => ({
    disabled: {
        color: gray,
        cursor: "initial"
    },
    toolbarContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
        backgroundColor: grayLight
    },
    toolbarLeftPart: {
        marginLeft: unit * 2,
        "& p": {
            fontFamily: "Nunito",
            fontSize: 20,
            lineHeight: "24px",
            fontWeight: 800
        },
        "& svg": {
            marginTop: unit * 2
        }
    },
    toolbarRightPart: {
        paddingRight: unit * 2,
        marginTop: unit * 2,
        marginBottom: 9,
        "& svg": {
            paddingLeft: unit * 4.5
        },
        "& input": {
            border: "none"
        }
    },
    searchContainer: {
        background: white,
        boxShadow: "0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19)",
        paddingBottom: unit*1.5
    },
    searchToolbarLeftPart: {
        marginLeft: unit * 2,
        "& svg": {
            marginTop: unit * 2,
            alignSelf: "center"
        }
    },
    searchToolbarRightPart: {
        display: "flex",
        flexDirection: "row",
        marginTop: unit * 2,
        marginRight: unit * 2,
        "& input": {
            border: "none",
            marginRight: unit * 2,
            width: "80vw",
            height: 28,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: unit * 2.5,
            lineHeight: `${unit * 3.5}px`,
            letterSpacing: "0.15px",
            alignItems: "center",
            "&::placeholder": {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: unit * 2.5,
                lineHeight: `${unit * 3.5}px`,
                letterSpacing: "0.15px",
                alignItems: "center",
                opacity: 0.3
            },
            "&:focus": {
                border: "none",
                outline: "none"
            }
        },
        "$ svg": {
            alignSelf: "center"
        }
    }
}));

export default styles;