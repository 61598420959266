import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import Parser from "html-react-parser";


import { State } from "../../store/entries/constants";
import { changeAppState, setCurrentEntry } from "../../store/entries/entries";
import Circle from "../Circle";
import useStyles from "./styles";
import { ItemProperties } from "./types";
import { showDayOfWeek } from "./utils";

const getContents = (content: string | JSX.Element | JSX.Element[] ): string | JSX.Element | JSX.Element[] => {
    if (Array.isArray(content)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const filteredContent = content.filter(item => item !== "\n").filter(item => item.props.children.type !== "br");
            
        const isLong = filteredContent.length > 5;
        if (isLong) {
            filteredContent.splice(5);
            const moreItem: JSX.Element = <p key="uniqueKey">...</p>;
            filteredContent.push(moreItem);
            return filteredContent;
        }
        
        return filteredContent;
    }
    return content;
    
};

export const Item = ({ entry }: ItemProperties) => {
    const dispatch = useDispatch();
    const {
        listItem,
        listFirstColumn,
        listRow,
        listSecondColumn,
        entryDate,
        entryTitle,
        entrySummary } = useStyles();

    const clickAction = (event:MouseEvent<HTMLElement>) => {
        event?.preventDefault();
        dispatch(setCurrentEntry(entry));
        dispatch(changeAppState(State.Entry));
    };

    const {color, created, content, title} = entry;

    const convertedContent = Parser(stateToHTML(convertFromRaw(JSON.parse(content))));

    return (
        <div className={listItem} onClick={clickAction}>
            <div className={listFirstColumn}>
                <Circle color={color} />
            </div>
            <div className={listSecondColumn}>
                <div className={listRow}>
                    <div className={entryTitle}>{(title === "") ? "No title" : title}</div>
                    <p className={entryDate}>{`${new Date(
                        created
                    ).getDate()}, ${showDayOfWeek(new Date(created).getDay())}.`}</p>
                </div>
                <div className={entrySummary}>
                    {getContents(convertedContent)}
                </div>
            </div>
        </div>
    );
};
