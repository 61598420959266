import { createUseStyles } from "react-jss";
import { AppTheme } from "../../theme/types";

const styles = createUseStyles(({unit, colors: {red, green, yellow, lightRed, lightGreen, lightYellow, gray}}: AppTheme) => ({
    moodContainer: {
        display: "flex",
        flexDirection: "row",
        margin: [unit * 2, 0, 0, unit]
    },
    moodLabel: {
        display: "inline-flex",
        padding: [unit, unit, unit, 0],
        margin: 0,
        cursor: "pointer"
    },
    circle: {
        display: "inline-flex",
        borderRadius: "50%",
        width: unit * 1.5,
        height: unit * 1.5,
        minWidth: unit * 1.5,
        minHeight: unit * 1.5,
        margin: [0, unit, -2, unit]
    },
    redCircle: {
        background: red
    },
    greenCircle: {
        background: green
    },
    yellowCircle: {
        background: yellow
    },
    mood: {
        border: [1, "solid", gray],
        alignSelf: "center",
        borderRadius: unit,
        marginLeft: unit
    },
    redMood: {
        composes: "$mood",
        backgroundColor: lightRed
    },
    greenMood: {
        composes: "$mood",
        backgroundColor: lightGreen
    },
    yellowMood: {
        composes: "$mood",
        backgroundColor: lightYellow
    },
    moodMenu: {
        position: "absolute",
        margin: unit * 1,
        marginTop: unit * 6.5,
        backgroundColor: "white",
        width: 200,
        borderRadius: unit,
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 20
    },
    moodItem: {
        margin: unit * 2,
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: unit * 1.75,
        lineHeight: `${unit * 2.5}px`,
        letterSpacing: "0.25px",
        cursor: "pointer"
    },
    activeMood: {
        fontStyle: "italic",
        cursor: "initial"
    }
}));

export default styles;