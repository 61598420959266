import { createUseStyles } from "react-jss";
import { AppTheme } from "../../theme/types";

const styles = createUseStyles(({ unit, colors: { surfaceOverlay, subtitleTwo, secondaryText, grayLight } }: AppTheme) => ({
    entryTitle: {
        fontWeight: 700,
        fontSize: unit * 1.75,
        display: "inline-flex",
        flex: [0, 0, "85%"],
        marginTop: unit * 1.5
    },
    scrollContainer: {
        overflow: "none",
        overflowY: "scroll",
        height: "95vh"
    },
    entryDate: {
        position: "absolute",
        right: unit * 4,
        display: "inline-flex",
        flex: [0, 0, "20%"],
        color: subtitleTwo,
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: unit * 1.5,
        lineHeight: `${unit * 3}px`
    },
    listFirstColumn: {
        display: "flex",
        flexBasis: 10,
        flex: [0, 0, "10%"],
        "& div": {
            margin: unit * 2.5
        }
    },
    listSecondColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: [0, 0, "70%"],
        paddingLeft: unit * 3,
        borderBottom: [1, "solid", surfaceOverlay]
    },
    listRow: {
        display: "flex",
        width: "100%"
    },
    listItem: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        paddingLeft: unit * 2,
        "&:hover": {
            backgroundColor: grayLight
        },
        cursor: "pointer",
        "&:last-of-type": {
            marginBottom: unit * 13.875
        }
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        width: "75vw",
        paddingLeft: unit * 2
    },
    list: {
        display: "flex",
        flex: [0, 0, "90%"],
        flexDirection: "column",
        width: "100%"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        marginTop: unit * 7
    },
    footerContainer: {
        display: "flex",
        flexDirection: "column"
    },
    footerFirstRow: {
        display: "flex",
        justifyContent: "flex-end",
        paddingBottom: unit,
        width: "100%"
    },
    footerSecondRow: {
        background: "#F6F6F6",
        display: "flex",
        justifyContent: "space-around",
        paddingTop: unit,
        "& img": {
            height: unit * 4,
            width: unit * 4
        }
    },
    monthLabel: {
        fontFamily: "Nunito",
        fontWeight: "800",
        fontSize: unit * 2.5,
        lineHeight: `${unit * 3}px`,
        color: "rgb(70,70,70, 0.3)",
        letterSpacing: "0.15px",
        padding: unit * 2,
        marginBottom: 0,
        "&:first-of-type": {
            marginTop: unit * 3.5
        }
    },
    entrySummary: {
        color: secondaryText,
        fontFamily: "Nunito",
        fontWeight: 400,
        fontSize: unit * 1.75,
        lineHeight: `${unit * 2.4}px`,
        "@global": {
            p: {
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: unit * 1.65,
                lineHeight: `${unit * 2.4}px`,
                margin: 0
            },
            h1: {
                fontFamily: "Nunito",
                fontWeight: 300,
                fontSize: unit * 2.9,
                lineHeight: `${unit * 3.55}px`,
                margin: 0
            },
            h2: {
                fontFamily: "Nunito",
                fontWeight: 300,
                fontSize: unit * 2.7,
                lineHeight: `${unit * 3.35}px`,
                margin: 0
            },
            h3: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 2.5,
                lineHeight: `${unit * 3.15}px`,
                margin: 0
            },
            h4: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 2.3,
                lineHeight: `${unit * 2.95}px`,
                margin: 0
            },
            h5: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 2.1,
                lineHeight: `${unit * 2.75}px`,
                margin: 0
            },
            h6: {
                fontFamily: "Nunito",
                fontWeight: 500,
                fontSize: unit * 1.9,
                lineHeight: `${unit * 2.55}px`,
                margin: 0
            },
            sub: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 1.8,
                lineHeight: `${unit * 2.45}px`
            }
        }
    },
    infiniteScroll: {
        overflowY: "scroll",
        height: "95vh"
    },
    footerSingleAddButton: {
        display: "inline",
        position: "fixed",
        bottom: unit * 6.5,
        right: 0
    }
}));

export default styles;
