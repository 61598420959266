import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Color } from "../../store/entries/constants";
import Circle from "../Circle";
import { DayTypes } from "./constants";
import useStyles from "./styles";

export interface MoodProperties {
    color: Color;
    onClick: (dayType: Color) => void;
}

export const showLabelOf = (mood: Color) => {
    switch (mood) {
    case Color.Green: {
        return `${DayTypes.Good} day`;
    }
    case Color.Red: {
        return `${DayTypes.Terrible} day`;
    }
    default: {
        return `${DayTypes.Normal} day`;
    }
    }
};

export const findCorrespondingColor = (colorString: string): Color => {
    switch (colorString) {
    case "Terrible": {
        return Color.Red;
    }
    case "Good": {
        return Color.Green;
    }
    default: {
        return Color.Yellow;
    }   
    }
};

export const Mood = ({color, onClick}: MoodProperties ) => {
    const { moodContainer, moodLabel, redMood, greenMood, yellowMood, moodMenu, moodItem, activeMood } = useStyles();
    const [ showMenu, toggleMenu ] = useState<boolean>(false);
    const modalMenuReference = useRef<HTMLDivElement>(null);

    useEffect(()=> {
        const handleClickOutside = (event: MouseEvent): void => {
            if (modalMenuReference.current && !modalMenuReference.current.contains(event?.target as Node)) {
                toggleMenu(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const menu = (currentColor: Color) => (
        <>
            {
                Object.values(DayTypes).map((dayType: string, index) => <p key={index} onClick={() => {
                    toggleMenu(!showMenu);
                    onClick(findCorrespondingColor(dayType));
                }} className={classNames({[activeMood]: currentColor === findCorrespondingColor(dayType)}, moodItem)}>{dayType} day</p>)
            }
        </>
    );

    return (
        <div className={moodContainer}>
            <div onClick={() => {
                toggleMenu(!showMenu);
            }}
            className={classNames({
                [redMood]: color === Color.Red,
                [greenMood]: color === Color.Green,
                [yellowMood]: color === Color.Yellow
            })}
            >
                <Circle color={color} />
                <p className={classNames(moodLabel)}>{showLabelOf(color)}</p>
            </div>
            {
                showMenu ? <div className={moodMenu} ref={modalMenuReference}>{menu(color)}</div> : <></>
            }
        </div>
    );
};
