import { createUseStyles } from "react-jss";
import { AppTheme } from "../../theme/types";

const styles = createUseStyles(({ unit, colors: { grayLight } }: AppTheme) => ({
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        position: "absolute",
        background: "white",
        transform: "translate(-50%, -50%)"
    },
    renameEntryContainer: {
        minWidth: "40vw",
        borderRadius: unit,
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)",
        "& button": {
            margin: unit * 2,
            padding: unit * 2,
            border: "none",
            cursor: "pointer",
            background: "white"
        }
    },
    headerRow: {
        display: "flex",
        flexDirection: "row",
        minHeight: "5vh",
        background: grayLight,
        justifyContent: "space-between",
        "& p": {
            margin: unit * 2,
            fontFamily: "Nunito",
            fontSize: unit * 2.5,
            fontWeight: 800,
            lineHeight: `${unit * 3}px`,
            letterSpacing: "0.15px"
        },
        "& svg": {
            margin: unit * 2
        }
    },
    mainRow: {
        display: "flex",
        flexDirection: "row",
        minHeight: "10vh",

        "& input": {
            margin: unit * 2.5,
            minWidth: "35vw",
            height: 28,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: unit * 2.5,
            lineHeight: `${unit * 3.5}px`,
            letterSpacing: "0.15px",
            alignItems: "center",
            "&::placeholder": {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: unit * 2.5,
                lineHeight: `${unit * 3.5}px`,
                letterSpacing: "0.15px",
                alignItems: "center",
                opacity: 0.3
            },
            "&:focus": {
                outline: "none"
            }
        }
    },
    footerRow: {
        display: "flex",
        flexDirection: "row",
        minHeight: "5vh",
        justifyContent: "flex-end"
    }
}));

export default styles;
