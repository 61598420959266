import {
    SearchOutlined,
    // MoreVert,
    Delete,
    ArrowBack,
    SaveAlt,
    Save,
    Close
} from "@mui/icons-material";
import React, { useState } from "react";

import { ToolbarProperties } from "./types";
import useStyles from "./styles";
import { State } from "../../store/entries/constants";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { setSearch } from "../../store/entries/entries";
import { debounce } from "lodash-es";

const Toolbar = ({ state, onClick, onSave, onDelete, onGeneratePdf, updated }: ToolbarProperties) => {
    const { toolbarContainer, toolbarLeftPart, toolbarRightPart, searchToolbarLeftPart, searchToolbarRightPart, searchContainer, disabled } = useStyles();
    const renderButtons = () => {
        return state === State.List ? (
            <div>
                <SearchOutlined onClick={() => {
                    toggleSearch(true);

                }} />
                {/*<MoreVert /> */}
            </div>
        ) : (
            <div>
                <Save
                    onClick={() => {
                        if (updated) {
                            onSave();
                        }
                    }}
                    className={classnames({
                        [disabled]: updated !== true
                    })}
                />
                <SaveAlt
                    onClick={() => {
                        onGeneratePdf();
                    }}
                />
                <Delete onClick={() => {
                    onDelete();
                }} />
            </div>
        );
    };
    const [showSearch, toggleSearch] = useState(false);
    const [search, changeSearch] = useState("");
    const dispatch = useDispatch();
    const updateSearch = debounce((query?: string): void => {
        changeSearch(query === undefined ? "" : query);
        dispatch(setSearch(query));
    }, 50);

    return (
        <div className={classnames(toolbarContainer, {
            [searchContainer]: showSearch
        })}>
            {showSearch ? 
                <>
                    <div className={searchToolbarLeftPart}>
                        <ArrowBack onClick={() => {toggleSearch(false); updateSearch();}}/>
                    </div>
                    <div className={searchToolbarRightPart}>
                        <form>
                            <input type='text' placeholder="Search" value={search} onChange={(event)=> {
                                updateSearch(event.target.value);
                            }} />
                        </form>
                        {
                            search === "" ? <></> : <Close onClick={()=> {updateSearch();}} />
                        }
                    </div>
                </> 
                : 
                <>
                    <div className={toolbarLeftPart}>
                        {state === State.List ?
                            <p>Feelings diary</p> :
                            <ArrowBack
                                onClick={() => {
                                    onClick();
                                }}
                            />
                        }
                    </div>
                    <div className={toolbarRightPart}>
                        {renderButtons()}
                    </div>
                </>}
        </div>
    );
};

export default Toolbar;
