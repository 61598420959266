import { jsPDF } from "jspdf";
import "../../fonts/nunitoNormal";

interface PdfFromReactProperties {
    target: any;
    name: string;
    orientation: "p" | "portrait" | "l" | "landscape";
    resize: boolean;
    debug: boolean;
    unit?: "pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc";
    format?: string | number[],
}

const pdfFromReact = ({target, name, orientation, resize, debug, unit = "pt", format = "a4"}: PdfFromReactProperties) => {
    if (resize) {
        document.querySelector(target).style.width =
      orientation === "p" ? "600px" : "841px";
        document.querySelector(target).style.minHeight =
      orientation === "p" ? "841px" : "595px";
    }
    const pdf = new jsPDF(orientation, unit, format);
    pdf.html(document.querySelector(target), {
        callback: () => {
        
            pdf.setFont("Nunito");
            console.log(pdf.getFontList());
            debug ? window.open(pdf.output("bloburl")) : pdf.save(`${name}.pdf`);
            if (resize) {
                document.querySelector(target).style = "";
            }
        }
    });

};
export { pdfFromReact };