import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "./main/app/store";
import App from "./main";
import reportWebVitals from "./reportWebVitals";

const container = document.querySelector("#root");

if (container) {
    const root = createRoot(container);

    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
