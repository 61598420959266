import { createUseStyles } from "react-jss";
import { AppTheme } from "../../../theme/types";

const styles = createUseStyles(({ unit, colors: { black, white } }: AppTheme) => ({
    addButtonContainer: {
        minWidth: unit * 7,
        height: unit * 7,
        margin: unit * 2,
        alignSelf: "center",
        display: "contents"
    },
    addSymbol: {
        color: white,
        zIndex: 999,
        minHeight: unit * 2,
        minWidth: unit * 2,
        height: unit * 14,
        width: unit * 14,
        paddingLeft: unit * 2
    },
    addBackground: {
        boxShadow: [0, 0, 2, 2, black],
        marginRight: unit,
        width: unit * 7,
        height: unit * 7,
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        background: black,
        "& svg": {
            width: unit * 3,
            height: unit * 3
        }
    }

}));

export default styles;
