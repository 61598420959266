import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../app/store";
import Entry from "../components/Entry";
import List from "../components/List";
import { State } from "../store/entries/constants";

const appStateSelector = (state: RootState) => state.entries.appState;

const MainPage = () => {
    const appState = useSelector(appStateSelector);

    return <>{appState === State.List ? <List /> : <Entry />}</>;
};

export default MainPage;
