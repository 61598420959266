import { Entry } from "../../store/entries/types";

export const showDayOfWeek = (date: number) => {
    switch (date) {
    case 1: {
        return "Mon";
    }
    case 2: {
        return "Tues";
    }
    case 3: {
        return "Wed";
    }
    case 4: {
        return "Thurs";
    }
    case 5: {
        return "Fri";
    }
    case 6: {
        return "Sat";
    }
    default: {
        return "Sun";
    }
    }
};

export const convertNumberToMonth = (id: number) => {
    switch (id) {
    case 1: {
        return "January";
    }
    case 2: {
        return "February";
    }
    case 3: {
        return "March";
    }
    case 4: {
        return "April";
    }
    case 5: {
        return "May";
    }
    case 6: {
        return "June";
    }
    case 7: {
        return "July";
    }
    case 8: {
        return "August";
    }
    case 9: {
        return "September";
    }
    case 10: {
        return "October";
    }
    case 11: {
        return "November";
    }
    default: {
        return "December";
    }
    }
};

export const getMonthTitle = (entry: Entry) => {
    return convertNumberToMonth(new Date(entry.created).getMonth() + 1);
};
