import "@fontsource/nunito";
import "@fontsource/roboto";
import React from "react";
import { ThemeProvider } from "react-jss";
import { useSelector } from "react-redux";
import { ModalWindow } from "./components/Modals";
import MainPage from "./pages/MainPage";
import { themeSelector
    , modalTypeSelector 
} from "./store/selectors";
import useGlobalStyles from "./styles";
import { theme } from "./theme";

const Wrapper = () => {
    useGlobalStyles();
    const getModalType = useSelector(modalTypeSelector);
    return <div>
        <MainPage />
        <ModalWindow modalType={getModalType} />
    </div>;
};

const App = () => {
    const selectedTheme = useSelector(themeSelector);

    return (
        <ThemeProvider theme={theme[selectedTheme]}>
            <Wrapper />
        </ThemeProvider>
    );
};

export default App;
