import { createUseStyles } from "react-jss";
import { AppTheme } from "../../theme/types";

const styles = createUseStyles(({unit, colors: {charcoal, blackLight, gray}}: AppTheme) => ({
    editorContainer: {
        minHeight: "80vh",
        cursor: "text",
        borderRadius: unit,
        margin: unit * 2,
        "@global": {
            p: {
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: unit * 1.65,
                lineHeight: `${unit * 2.4}px`,
                margin: 0
            },
            h1: {
                fontFamily: "Nunito",
                fontWeight: 300,
                fontSize: unit * 2.9,
                lineHeight: `${unit * 3.55}px`,
                margin: 0
            },
            h2: {
                fontFamily: "Nunito",
                fontWeight: 300,
                fontSize: unit * 2.7,
                lineHeight: `${unit * 3.35}px`,
                margin: 0
            },
            h3: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 2.5,
                lineHeight: `${unit * 3.15}px`,
                margin: 0
            },
            h4: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 2.3,
                lineHeight: `${unit * 2.95}px`,
                margin: 0
            },
            h5: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 2.1,
                lineHeight: `${unit * 2.75}px`,
                margin: 0
            },
            h6: {
                fontFamily: "Nunito",
                fontWeight: 500,
                fontSize: unit * 1.9,
                lineHeight: `${unit * 2.55}px`,
                margin: 0
            },
            sub: {
                fontFamily: "Nunito",
                fontWeight: 400,
                fontSize: unit * 1.8,
                lineHeight: `${unit * 2.45}px`
            }
        }
    },     
    tag: {
        display: "flex",
        flexDirection: "row",
        "& div": {
            border: [1, "solid", gray],
            borderRadius: unit,
            display: "flex",
            flexDirection: "row",
            width: unit * 8.75,
            height: unit * 2.5,
            padding: 2,
            marginLeft: unit * 2,
            marginTop: unit * 2,
            "& span": {
                marginLeft: unit,
                alignSelf: "center"
            }
        },
        "& svg": {
            alignSelf: "center",
            cursor: "pointer"
        }
    },  
    styleButton: {
        width: unit * 4,
        height: unit * 4,
        fontFamily: "Roboto",
        fontWeight: 700,
        color: blackLight,
        order: 2,
        flexGrow: 0,
        minWidth: unit * 4,
        minHeight: unit * 4,
        margin: unit,
        padding: unit * .5,
        display: "flex",
        alignContent: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        "& svg": {
            paddingLeft: 4
        }
    },  
    activeButton: {
        border: [1, "solid", gray],
        borderRadius: unit,
        cursor: "pointer"
    },
    inactiveButton: {
        border: [1, "solid", charcoal],
        cursor: "pointer",
        "&:hover": {
            border: [1, "solid", gray],
            borderRadius: unit
        }
    },      
    ".style_button svg, .style_button .label_button": {
        height: unit * 4,
        width: unit * 4,
        alignSelf: "stretch"
    },  
    richEditorControls: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center"
    },
    controlContainer: {
        flexDirection: "column",
        display: "flex",
        backgroundColor: charcoal,
        marginTop: unit * 6.5
    },
    labelButton: {
        fontFamily: "Roboto",
        fontWeight: 700,
        color: blackLight,
        paddingRight: 12,
        paddingLeft: 6
    },
    titleContainer: {
        alignSelf: "center",
        margin: 0,
        paddingTop: unit * 2,
        paddingLeft: unit,
        display: "flex",
        flexDirection: "row",
        "& svg": {
            marginLeft: unit
        },
        "& p": {
            marginBottom: 0
        },
        "& form": {
            marginTop: 0,
            minWidth: "50vw"
        }
    },
    titleInput: {
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: unit * 2.5,
        lineHeight: `${unit * 3}px`,
        letterSpacing: "0.15px",
        color: "rgba(36, 38, 41, 0.87)",
        marginTop: -unit / 2,
        "& input": {
            minWidth: "40vw",
            height: 28,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: unit * 2.5,
            lineHeight: `${unit * 3.5}px`,
            letterSpacing: "0.15px",
            alignItems: "center",
            opacity: 0.6,
            "&::placeholder": {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: unit * 2.5,
                lineHeight: `${unit * 3.5}px`,
                letterSpacing: "0.15px",
                alignItems: "center",
                opacity: 0.4
            },
            "&:focus": {
                outline: "none",
                opacity: "1 !important"
            }
        }
    }, 
    titleSave: {
        marginTop: unit / 2
    },
    titlePlace: {
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: unit * 2.5,
        lineHeight: `${unit * 3}px`,
        letterSpacing: "0.15px",
        color: "rgba(36, 38, 41, 0.87)",
        marginTop: 0
    },
    editorToolContainer: {
        display: "flex",
        flexDirection: "row"
    }
}));

export default styles;
