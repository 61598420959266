import {
    Close
} from "@mui/icons-material";

import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { setModalType } from "../../store/entries/entries";
import useStyles from "./styles";

export enum ModalType {
    TestModal = "ModalType",
    RenameEntry = "RenameEntry"
}

interface ModalWindowProperties {
  closeModal?(): void;
  afterOpenModal?(): void;
  modalType?: ModalType;
}

const selectModalType = (type: ModalType | undefined) => {
    const dispatch = useDispatch();
    const { renameEntryContainer, headerRow, mainRow, footerRow } = useStyles();
    const [search, setSearch] = useState<string>("");
    switch (type) {
    case ModalType.TestModal: { return <p>Test</p>;
    }
    case ModalType.RenameEntry: {
        return (
            <div className={renameEntryContainer}>
                <div className={headerRow}>
                    <p>Change title</p>
                    <Close onClick={() => {
                        dispatch(setModalType());
                    }} />
                </div>
                <div className={mainRow}>
                    <form>
                        <input type='text' placeholder="Title" value={search} onChange={(event)=> {setSearch(event.target.value);}} />
                    </form>
                </div>
                <div className={footerRow}>
                    <button onClick={() => {
                        dispatch(setModalType());
                    }}>Cancel</button><button onClick={() => {
                        dispatch(setModalType());
                    }}>Save</button>
                </div>
            </div>
        );
    }
                
    default: { return <></>;
    }
    }
};

export const ModalWindow = ({closeModal, afterOpenModal, modalType}: ModalWindowProperties) => {
    const {content} = useStyles();
    return <Modal
        isOpen={modalType !== undefined}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className={content}
        contentLabel="Example Modal"
    >
        {selectModalType(modalType)}
    </Modal>;
};
