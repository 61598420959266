import classnames from "classnames";
import React from "react";
import { Color } from "../../store/entries/constants";

import useStyles from "../MoodBlock/styles";
import { CircleBlockProperties } from "../MoodBlock/types";

const CircleBlock = ({ color }: CircleBlockProperties) => {
    const { circle, redCircle, greenCircle, yellowCircle } = useStyles();

    return (
        <div
            className={classnames(circle, {
                [redCircle]: color === Color.Red,
                [greenCircle]: color === Color.Green,
                [yellowCircle]: color === Color.Yellow
            })}
        />
    );
};

export default CircleBlock;
