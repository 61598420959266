import { noop } from "lodash-es";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

import { entriesSelector, searchStringSelector } from "../../store/selectors";
import { State } from "../../store/entries/constants";
import { changeAppState, setCurrentEntry } from "../../store/entries/entries";
import AddComponent from "../Buttons/AddButton/AddButton";
import Toolbar from "../Toolbar";
import MonthItem from "./MonthItem";
import useStyles from "./styles";
import { getMonthTitle } from "./utils";
import vkLogo from "../../assets/svg/vk_logo.svg";
import alphaLogo from "../../assets/svg/Logo.svg";
import { convertFromRaw } from "draft-js";

const List = () => {
    const search = useSelector(searchStringSelector);
    const entries = useSelector(entriesSelector);
    const filteredEntries = entries.filter((entry) => {
        if (search === undefined) {
            return entry;
        }
        const parsedContent = convertFromRaw(JSON.parse(entry.content));
        const text = parsedContent.getPlainText();
        return search && (entry.title.toLowerCase().includes(search.toLowerCase()) || text.toLowerCase().includes(search.toLowerCase())) ? entry : undefined;
    });
    const dispatch = useDispatch();

    const { list, footer, container, footerContainer, footerFirstRow, footerSecondRow, scrollContainer,footerSingleAddButton } = useStyles();

    const months: string[] = [];

    for ( const entry of filteredEntries ) {
        const month = getMonthTitle(entry);
        if (!months.includes(month)) {
            months.push(month);
        }
    }

    return (
        <>
            <header>
                <Toolbar
                    state={State.List}
                    onClick={() => {
                        dispatch(changeAppState(State.List));
                    }}
                    onSave={noop}
                    onDelete={noop}
                    onGeneratePdf={noop}
                />
            </header>
            <main>
                {filteredEntries.length > 0 ? (
                    <div className={container}>
                        <div className={list}>
                            <InfiniteScroll
                                next={() => {console.log("next");}}
                                hasMore={false}
                                loader={<p>Loading</p>}
                                dataLength={1}
                                className={scrollContainer}
                            >
                                {
                                    months.map((month, index) => (
                                        <MonthItem key={index} entries={filteredEntries.filter((entry)=> getMonthTitle(entry) === month)} monthName={month} />
                                    ))
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </main>
            <footer className={footerContainer}>
                {filteredEntries.length === 0 ? (
                    <div className={footerFirstRow}>
                        <div className={footer}>
                            {search === "" ? (
                                <p>No entries yet. Press &quot;+&quot; button to add one...</p>
                            ) : (
                                <p>No entries found. Try something else...</p>
                            )}
                            
                        </div>
                        <AddComponent
                            onClick={() => {
                                dispatch(setCurrentEntry());
                                dispatch(changeAppState(State.AddEntry));
                            }}
                        />
                    </div>
                ) : (
                    <div className={footerSingleAddButton}>
                        <AddComponent
                            onClick={() => {
                                dispatch(setCurrentEntry());
                                dispatch(changeAppState(State.AddEntry));
                            }}
                        />
                    </div>
                )}
                <div className={footerSecondRow}>
                    <div>
                        <a href="https://vk.com/thefeelingsdiary">
                            <img src={vkLogo} alt="VK Logo" />
                        </a>
                    </div>
                    <div>
                        <a href="https://alpha-function.ru/">
                            <img src={alphaLogo} alt="Alpha Function Logo" />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default List;
