import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../components/Modals";
import { State, Theme } from "./constants";
import { Entry } from "./types";

export interface EntriesState {
    currentEntry?: Entry;
    entries: Entry[];
    appState: State;
    selectedTheme: Theme;
    modalType?: ModalType;
    searchString?: string;
}

const populateEntries = (): [] => {
    const entriesFromStorage = localStorage.getItem("entries");
    const entries = entriesFromStorage ? JSON.parse(entriesFromStorage) : [];
    return entries;
};

const initialState: EntriesState = {
    entries: populateEntries(),
    appState: State.List,
    selectedTheme: Theme.Light
};

export const entiesSlice = createSlice({
    name: "entries",
    initialState,
    reducers: {
        addEntry: (state, action: PayloadAction<Entry>) => {
            state.entries.push(action.payload);
            localStorage.setItem("entries", JSON.stringify(state.entries));
        },
        setCurrentEntry: (state, action: PayloadAction<Entry | undefined>) => {
            state.currentEntry = action.payload;
        },
        updateEntry: (state, action: PayloadAction<Entry>) => {
            const index = state.entries.findIndex((item => item.id === action.payload.id));
            state.entries[index] = action.payload;
            localStorage.setItem("entries", JSON.stringify(state.entries));
        },
        deleteEntry: (state, action: PayloadAction<Entry>) => {
            const filteredEntries = state.entries.filter((entry) => entry.id !== action.payload.id);
            state.entries = filteredEntries;
            localStorage.setItem("entries", JSON.stringify(state.entries));
        },
        changeAppState: (state, action: PayloadAction<State>) => {
            state.appState = action.payload;
        },
        changeTheme: (state, action: PayloadAction<Theme>) => {
            state.selectedTheme = action.payload;
        },
        setModalType: (state, action: PayloadAction<ModalType|undefined>) => {
            state.modalType = action.payload;
        },
        setSearch: (state, action: PayloadAction<string|undefined>) =>{
            state.searchString = action.payload;
        }
    }
});

export const {addEntry, setCurrentEntry, updateEntry, deleteEntry, changeAppState, changeTheme, setModalType, setSearch} = entiesSlice.actions;

export default entiesSlice.reducer;
