import {
    DriveFileRenameOutlineTwoTone,
    FormatBold,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
    FormatUnderlined,
    InsertPhoto,
    Mic,
    Redo,
    Undo,
    Save
} from "@mui/icons-material";
import classNames from "classnames";
import { 
    convertFromRaw, convertToRaw, Editor, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { pdfFromReact } from "./utils";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEntry, changeAppState, deleteEntry, setCurrentEntry, updateEntry } from "../../store/entries/entries";
import { Color, State } from "../../store/entries/constants";
import {Mood} from "../MoodBlock";
import Toolbar from "../Toolbar";
import useStyles from "./styles";
import {
    BlockStyleControlsProperties,
    InlineStyleControlsProperties,
    StyleButtonProperties
} from "./types";
import { currentEntrySelector, entriesSelector } from "../../store/selectors";

const StyleButton = ({ onToggle, style, active, label }: StyleButtonProperties) => {
    const onToggleAction = (event: SyntheticEvent<EventTarget>) => {
        event.preventDefault();
        onToggle(style);
    };

    const { labelButton } = useStyles();

    const selectButton = (label: string) => {
        switch (label) {
        case "Bold": {
            return <FormatBold />;
        }
        case "Italic": {
            return <FormatItalic />;
        }
        case "Underline": {
            return <FormatUnderlined />;
        }
        case "UL": {
            return <FormatListBulleted />;
        }
        case "OL": {
            return <FormatListNumbered />;
        }
        case "Blockquote": {
            return <FormatQuote />;
        }
        case "Mic": {
            return <Mic />;
        }
        case "Pic": {
            return <InsertPhoto />;
        }
        case "Undo": {
            return <Undo />;
        }
        case "Redo": {
            return <Redo />;
        }
        default: {
            return <span className={labelButton}>{label}</span>;
        }
        }
    };

    const { styleButton, activeButton, inactiveButton } = useStyles();

    return (
        <span
            className={classNames(
                styleButton,
                active ? activeButton : inactiveButton
            )}
            onMouseDown={onToggleAction}
        >
            {selectButton(label)}
        </span>
    );
};

const BLOCK_TYPES = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
    { label: "Blockquote", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" }
];

const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" }
];

const Entry = () => {
    const entries = useSelector(entriesSelector);
    const currentEntry = useSelector(currentEntrySelector);
    const content = currentEntry?.content;

    const [editorState, setEditorState] = React.useState(
        () =>
            (content) ? 
                EditorState.createWithContent(convertFromRaw(JSON.parse(content))) : 
                EditorState.createEmpty()
    );

    const editor = React.useRef<Editor>(null);
    const focusEditor = () => {
        if (
            editor !== null &&
            editor.current !== null &&
            Object.prototype.hasOwnProperty.call(editor.current, "focus")
        ) {
            editor.current.focus();
        }
    };

    const BlockStyleControls = (properties: BlockStyleControlsProperties) => {
        const { editorState, onToggle } = properties;
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();
        const { richEditorControls } = useStyles();

        return (
            <div className={richEditorControls}>
                {BLOCK_TYPES.map((type) => (
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={onToggle}
                        style={type.style}
                    />
                ))}
            </div>
        );
    };

    const onChange = (state: EditorState) => {
        if (editorState.getCurrentContent() !== state.getCurrentContent()) {
            toggleUpdate(true);
        }
        setEditorState(state);
    };

    const InlineStyleControls = (properties: InlineStyleControlsProperties) => {
        const currentStyle = properties.editorState.getCurrentInlineStyle();
        const { richEditorControls } = useStyles();
        return (
            <div className={richEditorControls}>
                {INLINE_STYLES.map((type) => (
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={properties.onToggle}
                        style={type.style}
                    />
                ))}
            </div>
        );
    };

    const toggleBlockType = (blockType: string) => {
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle: string) => {
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const dispatch = useDispatch();

    const { editorToolContainer, titleSave,controlContainer, editorContainer, titleInput, titlePlace, titleContainer } = useStyles();
    const [ currentMood, setCurrentMood ] = useState<Color>(currentEntry?.color ?? Color.Green);
    const [ update, toggleUpdate ] = useState(false);

    useEffect(()=> {
        if (update) {
            toggleUpdate(false);
        }
    }, [entries]);

    const [editEnabled, enableEdit] = useState<boolean>(currentEntry === undefined);
    const [title, changeTitle] = useState<string>(currentEntry?.title ?? "");

    return (
        <>
            <header>
                <Toolbar
                    state={State.Entry}
                    updated={update}
                    onClick={() => {
                        dispatch(changeAppState(State.List));
                    }}
                    onSave={() => {
                        const entry = {
                            color: currentMood,
                            id: currentEntry?.id ?? entries.length,
                            content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                            created: new Date().toString(),
                            title: title
                        };
                        if (currentEntry === undefined) {
                            dispatch(setCurrentEntry(entry));
                            dispatch(addEntry(entry));
                        } else {
                            dispatch(updateEntry(entry));
                        }
                        toggleUpdate(true);
                    }}
                    onGeneratePdf={() => {
                        pdfFromReact({target: ".DraftEditor-editorContainer", name: "entry", orientation: "p", resize: false, debug: false});
                    }}
                    onDelete={() => {
                        if (currentEntry !== undefined) {
                            dispatch(deleteEntry(currentEntry));
                        }
                        toggleUpdate(true);
                        dispatch(changeAppState(State.List));
                    }}
                />
            </header>
            <main>
                <div className={controlContainer}>
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                </div>
                <div className={editorToolContainer}>
                    <Mood color={currentMood} onClick={(color: Color) => {
                        toggleUpdate(true);
                        setCurrentMood(color);
                    }}/>
                    <div className={titleContainer}>
                        {
                            (editEnabled) ? 
                                <>
                                    <form className={titleInput}>
                                        <input type='text' placeholder="Enter title" value={title} onChange={(event)=> {
                                            changeTitle(event.target.value);
                                        }} />
                                    </form>
                                    <Save
                                        className={titleSave}
                                        onClick={() => {
                                            toggleUpdate(true);
                                            enableEdit(!editEnabled);
                                        }} />
                                </>
                                : 
                                <>
                                    <p className={titlePlace}>{title}</p>
                                    <DriveFileRenameOutlineTwoTone onClick={() => {
                                        enableEdit(!editEnabled);
                                    }} />
                                </>
                        }
                    </div>
                </div>
                <div className={editorContainer} onClick={focusEditor}>
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={onChange}
                        placeholder="Write something!"
                    />
                </div>
            </main>
            <footer />
        </>
    );
};

export default Entry;
